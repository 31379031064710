<template>
  <div>
    <h3 class="text-center">News Tags</h3>
    

    
    <b-row>
      <p class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          v-if="loading"
          label="Large Spinner"
        ></b-spinner>
      </p>

      <p
        class="text-center"
        style="color: red"
        v-if="items && items.length == 0"
      >
        there is no data
      </p>
      <b-col md="3" v-for="(item, index) in items" :key="index">
       
          <b-card-text class="hideContent vertical-center" style="border:solid 1px;padding:30px">
            {{ item.name }}
            <div style="    display: flex;
    justify-content: end;
    width: -webkit-fill-available;">
              <b-button
            size="sm"
            
              variant="danger"
              
              @click="globalDelete('tags', item.id)"
            >
              <b-spinner
                style="width: 5rem; height: 5rem"
                v-if="loading"
              ></b-spinner>
              Delete</b-button
            >  
            </div>
            
          </b-card-text>

          
            
            
          
        </b-card>
      </b-col>
    </b-row>
   
  </div>
</template>
<script>
const {
 
  getFields,
 
} = require("../../assets/js/service");
export default {
  watch: {
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
    "$store.state.viewReload": {
      handler() {
        if (this.$store.state.viewReload) {
          this.onCreateLoading();
          this.$store.commit("set", ["viewReload", false]);
        }
      },
    },
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
     
      loading: false,
      
      isBusy: false,
      items: null,
    };
  },
  mounted() {
    this.onCreateLoading();
  },
  methods: {
    onCreateLoading() {
      let token = localStorage.getItem("token");
      this.$store.commit("set", ["loading", true]);
    
   
        
        getFields("tags", token).then((resp) => {
          this.items = resp.data;this.$store.commit("set", ["loading", false]);
        });
    
    },

   
  
  },
};
</script>
<style>
</style>